/* Contenedor del video de fondo */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Mantener el video en el fondo */
}

.video-background iframe {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

/* Estilos generales */
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 15, 15, 0.8);
  overflow: hidden;
}

/* Tarjeta transparente */
.neon-card {
  background: rgba(20, 20, 20, 0.6); /* Fondo oscuro translúcido */
  padding: 50px 70px;
  border-radius: 25px;
  box-shadow: 
    0 0 15px rgba(255, 255, 255, 0.15), 
    0 0 40px rgba(255, 255, 255, 0.1);
  transition: 0.5s ease-in-out;
  animation: floating 5s infinite ease-in-out;
}

.neon-card:hover {
  box-shadow: 
    0 0 30px rgba(255, 255, 255, 0.7), 
    0 0 60px rgba(255, 255, 255, 0.5);
  transform: scale(1.05);
}

/* Nombre estilizado */
.name {
  font-size: 3.5rem;
  color: #ffffff;
  text-shadow: 
    0 0 15px rgba(255, 255, 255, 0.8), 
    0 0 30px rgba(255, 255, 255, 0.6);
  margin-bottom: 30px;
}

/* Contenedor de enlaces sociales */
.social-links {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.instagram-link, .steam-link {
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.instagram-icon, .steam-icon {
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.5);
  transition: transform 0.4s, color 0.4s, text-shadow 0.4s;
  text-shadow: 
    0 0 15px rgba(255, 255, 255, 0.4), 
    0 0 30px rgba(255, 255, 255, 0.3);
}

/* Hover con efectos neón */
.instagram-icon:hover, .steam-icon:hover {
  transform: rotate(360deg) scale(1.3);
  color: rgba(255, 255, 255, 1);
  text-shadow: 
    0 0 25px rgba(255, 255, 255, 0.8), 
    0 0 50px rgba(255, 255, 255, 0.6);
}

/* Animación flotante */
@keyframes floating {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}
